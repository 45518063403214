// Notifies user that their browser is out of date.
// https://browser-update.org/#install
// https://www.troyhunt.com/the-javascript-supply-chain-paradox-sri-csp-and-trust-in-third-party-libraries/
var $buoop = {required:{e:-4,f:-3,o:-3,s:-1,c:-3},insecure:true,unsupported:true,api:2019.07 };
function $buo_f(){
   var e = document.createElement("script");
   e.src = "https://browser-update.org/update.min.js";
   document.body.appendChild(e);
};
try {document.addEventListener("DOMContentLoaded", $buo_f,false)}
catch(e){window.attachEvent("onload", $buo_f)}
